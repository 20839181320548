<template>
  <!-- 素材列表页 -->
  <div class="pdf">
    <!-- 左侧模块 -->
    <div class="left-module">
      <div class="addnewProject">
        <!-- 班级列表 -->
        <div>
          <div class="projectListBox">
            <div class="searchBox">
              <span style="width: 73%;">
                <el-input v-model="search" placeholder="请输入内容"></el-input>
              </span>
              <span style="width: 23%; margin-right: 10px;">
                <el-button icon="el-icon-search" @click="getSearchData" ></el-button>
              </span>
            </div>
            <!-- 拖拽排序时显示 -->
            <table class="sortableTable" >
              <tr :id="item.id" v-for="(item, index) in groupList" :key="index">
                <!-- 班级名称 -->
                <td class="td-project" :style="item.type == 1 ? 'color:red' : ''"
                  @click="getcurrentActive(item.id)">
                  <i class="el-icon-success green" v-if="item.isopen"></i>
                  {{ item.name }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧模块 -->
    <div class="div border0">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="总分" name="first">
            <div class="TopTable">
                <table>
                  <caption style="margin-bottom: 0.521vw;" align="top">总分分布</caption> 
                  <tr>
                    <td>分数段</td>
                    <td>[90,100]</td>
                    <td>[80,90)</td>
                    <td>[70,80)</td>
                    <td>[60,70)</td>
                    <td>[0,60)</td>
                    <td>最高分</td>
                    <td>最低分</td>
                    <td>平均分</td>
                    <td>及格率</td>
                    <td>相对偏差</td>
                  </tr>
                  <tr>
                    <td>人数</td>
                    <td>{{ projectTotal.ten_nine_number ? projectTotal.ten_nine_number : 0 }}</td>
                    <td>{{ projectTotal.nine_eight_number ? projectTotal.nine_eight_number : 0 }}</td>
                    <td>{{ projectTotal.eight_seven_number ? projectTotal.eight_seven_number : 0 }}</td>
                    <td>{{ projectTotal.seven_six_number ? projectTotal.seven_six_number : 0 }}</td>
                    <td>{{ projectTotal.zero_six_number ? projectTotal.zero_six_number : 0 }}</td>
                    <td>{{ projectTotal.maxScore ? projectTotal.maxScore : 0 }}</td>
                    <td>{{ projectTotal.minScore ? projectTotal.minScore : 0 }}</td>
                    <td>{{ projectTotal.avgScore ? projectTotal.avgScore : 0 }}</td>
                    <td>{{ projectTotal.six_percent ? projectTotal.six_percent : 0 }}% </td>
                    <td>{{ projectTotal.relativeDev ? projectTotal.relativeDev : 0 }}%</td>
                  </tr>
                  <tr>
                    <td>百分比</td>
                    <td>{{ projectTotal.ten_nine_percent ? projectTotal.ten_nine_percent : 0  }}%</td>
                    <td>{{ projectTotal.nine_eight_percent ? projectTotal.nine_eight_percent : 0  }}%</td>
                    <td>{{ projectTotal.eight_seven_percent ? projectTotal.eight_seven_percent : 0  }}%</td>
                    <td>{{ projectTotal.seven_six_percent ? projectTotal.seven_six_percent : 0  }}%</td>
                    <td>{{ projectTotal.zero_six_percent ? projectTotal.zero_six_percent : 0  }}%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
              </table>
            </div>
            <div class="left" style="width: 60%;">
              <div id="projectHistogram" style="width:100%;height:66vh"></div>
            </div>
            <div class="right" style="width: 40%;">
              <div id="projectRadar" style="width:100%;height:66vh;"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="虚拟" name="second">
            <div class="TopTable">
              <table>
                <caption style="margin-bottom: 0.521vw;" align="top">虚拟成绩分布</caption> 
                <tr>
                  <td>分数段</td>
                  <td>[90,100]</td>
                  <td>[80,90)</td>
                  <td>[70,80)</td>
                  <td>[60,70)</td>
                  <td>[0,60)</td>
                  <td>最高分</td>
                  <td>最低分</td>
                  <td>平均分</td>
                  <td>及格率</td>
                  <td>相对偏差</td>
                </tr>
                <tr>
                  <td>人数</td>
                  <td>{{ projectExp.ten_nine_number ? projectExp.ten_nine_number : 0 }}</td>
                  <td>{{ projectExp.nine_eight_number ? projectExp.nine_eight_number : 0 }}</td>
                  <td>{{ projectExp.eight_seven_number ? projectExp.eight_seven_number : 0 }}</td>
                  <td>{{ projectExp.seven_six_number ? projectExp.seven_six_number : 0 }}</td>
                  <td>{{ projectExp.zero_six_number ? projectExp.zero_six_number : 0 }}</td>
                  <td>{{ projectExp.maxScore ? projectExp.maxScore : 0 }}</td>
                  <td>{{ projectExp.minScore ? projectExp.minScore : 0 }}</td>
                  <td>{{ projectExp.avgScore ? projectExp.avgScore : 0 }}</td>
                  <td>{{ projectExp.six_percent ? projectExp.six_percent : 0 }}% </td>
                  <td>{{ projectExp.relativeDev ? projectExp.relativeDev : 0 }}%</td>
                </tr>
                <tr> 
                  <td>百分比</td>
                  <td>{{ projectExp.ten_nine_percent ? projectExp.ten_nine_percent : 0  }}%</td>
                  <td>{{ projectExp.nine_eight_percent ? projectExp.nine_eight_percent : 0  }}%</td>
                  <td>{{ projectExp.eight_seven_percent ? projectExp.eight_seven_percent : 0  }}%</td>
                  <td>{{ projectExp.seven_six_percent ? projectExp.seven_six_percent : 0  }}%</td>  
                  <td>{{ projectExp.zero_six_percent ? projectExp.zero_six_percent : 0  }}%</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </table>
          </div>
          <div id="projectExp" style="width:100%;height:66vh;"></div>
          </el-tab-pane>
          <el-tab-pane label="测试" name="three">
            <div class="TopTable">
              <table>
                <caption style="margin-bottom: 0.521vw;" align="top">测试成绩分布</caption> 
                <tr>
                  <td>分数段</td>
                  <td>[90,100]</td>
                  <td>[80,90)</td>
                  <td>[70,80)</td>
                  <td>[60,70)</td>
                  <td>[0,60)</td>
                  <td>最高分</td>
                  <td>最低分</td>
                  <td>平均分</td>
                  <td>及格率</td>
                  <td>相对偏差</td>
                </tr>
                <tr>
                  <td>人数</td>
                  <td>{{ projectTest.ten_nine_number ? projectTest.ten_nine_number : 0 }}</td>
                  <td>{{ projectTest.nine_eight_number ? projectTest.nine_eight_number : 0 }}</td>
                  <td>{{ projectTest.eight_seven_number ? projectTest.eight_seven_number : 0 }}</td>
                  <td>{{ projectTest.seven_six_number ? projectTest.seven_six_number : 0 }}</td>
                  <td>{{ projectTest.zero_six_number ? projectTest.zero_six_number : 0 }}</td>
                  <td>{{ projectTest.maxScore ? projectTest.maxScore : 0 }}</td>
                  <td>{{ projectTest.minScore ? projectTest.minScore : 0 }}</td>
                  <td>{{ projectTest.avgScore ? projectTest.avgScore : 0 }}</td>
                  <td>{{ projectTest.six_percent ? projectTest.six_percent : 0 }}% </td>
                  <td>{{ projectTest.relativeDev ? projectTest.relativeDev : 0 }}%</td>
                </tr>
                <tr> 
                  <td>百分比</td>
                  <td>{{ projectTest.ten_nine_percent ? projectTest.ten_nine_percent : 0  }}%</td>
                  <td>{{ projectTest.nine_eight_percent ? projectTest.nine_eight_percent : 0  }}%</td>
                  <td>{{ projectTest.eight_seven_percent ? projectTest.eight_seven_percent : 0  }}%</td>
                  <td>{{ projectTest.seven_six_percent ? projectTest.seven_six_percent : 0  }}%</td>  
                  <td>{{ projectTest.zero_six_percent ? projectTest.zero_six_percent : 0  }}%</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </table>
          </div>
          <div id="projectTest" style="width:100%;height:66vh;"></div>
          </el-tab-pane>
          <el-tab-pane label="操作" name="four">
            <div class="TopTable">
              <table>
                <caption style="margin-bottom: 0.521vw;" align="top">操作成绩分布</caption> 
                <tr>
                  <td>分数段</td>
                  <td>[90,100]</td>
                  <td>[80,90)</td>
                  <td>[70,80)</td>
                  <td>[60,70)</td>
                  <td>[0,60)</td>
                  <td>最高分</td>
                  <td>最低分</td>
                  <td>平均分</td>
                  <td>及格率</td>
                  <td>相对偏差</td>
                </tr>
                <tr>
                  <td>人数</td>
                  <td>{{ projectOpt.ten_nine_number ? projectOpt.ten_nine_number : 0 }}</td>
                  <td>{{ projectOpt.nine_eight_number ? projectOpt.nine_eight_number : 0 }}</td>
                  <td>{{ projectOpt.eight_seven_number ? projectOpt.eight_seven_number : 0 }}</td>
                  <td>{{ projectOpt.seven_six_number ? projectOpt.seven_six_number : 0 }}</td>
                  <td>{{ projectOpt.zero_six_number ? projectOpt.zero_six_number : 0 }}</td>
                  <td>{{ projectOpt.maxScore ? projectOpt.maxScore : 0 }}</td>
                  <td>{{ projectOpt.minScore ? projectOpt.minScore : 0 }}</td>
                  <td>{{ projectOpt.avgScore ? projectOpt.avgScore : 0 }}</td>
                  <td>{{ projectOpt.six_percent ? projectOpt.six_percent : 0 }}% </td>
                  <td>{{ projectOpt.relativeDev ? projectOpt.relativeDev : 0 }}%</td>
                </tr>
                <tr> 
                  <td>百分比</td>
                  <td>{{ projectOpt.ten_nine_percent ? projectOpt.ten_nine_percent : 0  }}%</td>
                  <td>{{ projectOpt.nine_eight_percent ? projectOpt.nine_eight_percent : 0  }}%</td>
                  <td>{{ projectOpt.eight_seven_percent ? projectOpt.eight_seven_percent : 0  }}%</td>
                  <td>{{ projectOpt.seven_six_percent ? projectOpt.seven_six_percent : 0  }}%</td>  
                  <td>{{ projectOpt.zero_six_percent ? projectOpt.zero_six_percent : 0  }}%</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </table>
          </div>
          <div id="projectOpt" style="width:100%;height:66vh;"></div>
          </el-tab-pane>
          <el-tab-pane label="报告" name="five">
            <div class="TopTable">
              <table>
                <caption style="margin-bottom: 0.521vw;" align="top">报告成绩分布</caption> 
                <tr>
                  <td>分数段</td>
                  <td>[90,100]</td>
                  <td>[80,90)</td>
                  <td>[70,80)</td>
                  <td>[60,70)</td>
                  <td>[0,60)</td>
                  <td>最高分</td>
                  <td>最低分</td>
                  <td>平均分</td>
                  <td>及格率</td>
                  <td>相对偏差</td>
                </tr>
                <tr>
                  <td>人数</td>
                  <td>{{ projectReport.ten_nine_number ? projectReport.ten_nine_number : 0 }}</td>
                  <td>{{ projectReport.nine_eight_number ? projectReport.nine_eight_number : 0 }}</td>
                  <td>{{ projectReport.eight_seven_number ? projectReport.eight_seven_number : 0 }}</td>
                  <td>{{ projectReport.seven_six_number ? projectReport.seven_six_number : 0 }}</td>
                  <td>{{ projectReport.zero_six_number ? projectReport.zero_six_number : 0 }}</td>
                  <td>{{ projectReport.maxScore ? projectReport.maxScore : 0 }}</td>
                  <td>{{ projectReport.minScore ? projectReport.minScore : 0 }}</td>
                  <td>{{ projectReport.avgScore ? projectReport.avgScore : 0 }}</td>
                  <td>{{ projectReport.six_percent ? projectReport.six_percent : 0 }}% </td>
                  <td>{{ projectReport.relativeDev ? projectReport.relativeDev : 0 }}%</td>
                </tr>
                <tr> 
                  <td>百分比</td>
                  <td>{{ projectReport.ten_nine_percent ? projectReport.ten_nine_percent : 0  }}%</td>
                  <td>{{ projectReport.nine_eight_percent ? projectReport.nine_eight_percent : 0  }}%</td>
                  <td>{{ projectReport.eight_seven_percent ? projectReport.eight_seven_percent : 0  }}%</td>
                  <td>{{ projectReport.seven_six_percent ? projectReport.seven_six_percent : 0  }}%</td>  
                  <td>{{ projectReport.zero_six_percent ? projectReport.zero_six_percent : 0  }}%</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </table>
          </div>
          <div id="projectReport" style="width:100%;height:66vh;"></div>
          </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<style lang="scss" >
@import "@/assets/css/teacher/group/groupListnoScoped.scss";
.groupCode{
  width:100%;height:500px;
}
.pdf .expSearch
{
    position: fixed;
    top: 5.6vw !important;
    right: 2.5vw;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #0D5570 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #0D5570 !important;
  background: #0D5570 !important;
}

.el-dialog__body {
  padding: 30px 20px;
  padding-top: 5px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-bottom: 30px;
  text-align: center;
}

.pdf .is-active {
  border-left: 0 !important;
}

.weight .el-table .el-table__cell {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/DY/fenxi.scss";
</style>

<script>
  import Calendar from "@/assets/js/DY/fenxi.js";
  export default {
    ...Calendar
  };
</script>
